// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ProjectPortfolio from "../../blocks/ProjectPortfolio/src/ProjectPortfolio";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import SubscriptionBilling from "../../blocks/SubscriptionBilling/src/SubscriptionBilling";
import ExpenseTracking from "../../blocks/ExpenseTracking/src/ExpenseTracking";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import TimeTrackingBilling from "../../blocks/TimeTrackingBilling/src/TimeTrackingBilling";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import ReceiptCustomisation from "../../blocks/ReceiptCustomisation/src/ReceiptCustomisation";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import UserGroups from "../../blocks/UserGroups/src/UserGroups";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import CfTallyIntegration2 from "../../blocks/CfTallyIntegration2/src/CfTallyIntegration2";
import FormApprovalWorkflow from "../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import TabletSupport63 from "../../blocks/TabletSupport63/src/TabletSupport63";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import TaskGroups from "../../blocks/TaskGroups/src/TaskGroups";
import ProjecttaskTracking2 from "../../blocks/ProjecttaskTracking2/src/ProjecttaskTracking2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import BudgetingForecasting from "../../blocks/BudgetingForecasting/src/BudgetingForecasting";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import TaskList from "../../blocks/TaskList/src/TaskList";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import PushNotificationIntegration3 from "../../blocks/PushNotificationIntegration3/src/PushNotificationIntegration3";
import FullTeamVisibility2 from "../../blocks/FullTeamVisibility2/src/FullTeamVisibility2";
import CustomizableFormWorkflow3 from "../../blocks/CustomizableFormWorkflow3/src/CustomizableFormWorkflow3";
import TaskBlocker from "../../blocks/TaskBlocker/src/TaskBlocker";
import Analytics from "../../blocks/analytics/src/Analytics";
import Customform from "../../blocks/customform/src/Customform";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import Download from "../../blocks/Download/src/Download";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Print from "../../blocks/Print/src/Print";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Notifications from "../../blocks/notifications/src/Notifications";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";



const routeMap = {
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ProjectPortfolio:{
 component:ProjectPortfolio,
path:"/ProjectPortfolio"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
SubscriptionBilling:{
 component:SubscriptionBilling,
path:"/SubscriptionBilling"},
ExpenseTracking:{
 component:ExpenseTracking,
path:"/ExpenseTracking"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
TimeTrackingBilling:{
 component:TimeTrackingBilling,
path:"/TimeTrackingBilling"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
ReceiptCustomisation:{
 component:ReceiptCustomisation,
path:"/ReceiptCustomisation"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
UserGroups:{
 component:UserGroups,
path:"/UserGroups"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
TaskAllocator:{
 component:TaskAllocator,
path:"/TaskAllocator"},
CfTallyIntegration2:{
 component:CfTallyIntegration2,
path:"/CfTallyIntegration2"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
TabletSupport63:{
 component:TabletSupport63,
path:"/TabletSupport63"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
TaskGroups:{
 component:TaskGroups,
path:"/TaskGroups"},
ProjecttaskTracking2:{
 component:ProjecttaskTracking2,
path:"/ProjecttaskTracking2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
BudgetingForecasting:{
 component:BudgetingForecasting,
path:"/BudgetingForecasting"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
PushNotificationIntegration3:{
 component:PushNotificationIntegration3,
path:"/PushNotificationIntegration3"},
FullTeamVisibility2:{
 component:FullTeamVisibility2,
path:"/FullTeamVisibility2"},
CustomizableFormWorkflow3:{
 component:CustomizableFormWorkflow3,
path:"/CustomizableFormWorkflow3"},
TaskBlocker:{
 component:TaskBlocker,
path:"/TaskBlocker"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Customform:{
 component:Customform,
path:"/Customform"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
Download:{
 component:Download,
path:"/Download"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Print:{
 component:Print,
path:"/Print"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},

  Home: {
component:LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
